const coordMatcher = /^\d{1,3}\.\d*,\d{1,3}\.\d*$/;

// Tips and tricks https://stackoverflow.com/a/1140335/694325
class GPSService {
  static fixCoords(thing) {
    if (typeof thing === 'string') {
      if (thing.match(coordMatcher)) {
        const ref = thing.indexOf(',');
        const part1 = thing.slice(0, ref);
        const part2 = thing.slice(ref + 1);
        return { lat: Number(part1), lng: Number(part2) };
      }
      try { return JSON.parse(thing); } catch {}
    }

    if (thing) { console.log('Broken coordinates', thing); }

    return { lat: window.Avainia.config.coordinates.lat, lng: window.Avainia.config.coordinates.lng }; // TODO: DEDUPE
  }
}

export default GPSService;
