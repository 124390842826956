import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Modal, Form } from 'react-bootstrap';
import moment from 'moment';
import AvainiaCore from 'avainia-core-api';
import DatePicker from 'react-datepicker';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';

class EditScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completion: null,
      description: '',
      start_at: '',
      end_at: '',
      estimate_start_at: '',
      estimate_end_at: '',
      parent_id: 0,
      schedule_id: false,
      loading: false,
      error: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.schedule) { return; }
    if (this.props.schedule.id === this.state.schedule_id) { return; }

    this.setState({
      completion: this.props.schedule.completion_percentage,
      description: this.props.schedule.description,
      start_at: moment(this.props.schedule.start_at).toDate(),
      end_at: moment(this.props.schedule.end_at).toDate(),
      estimate_start_at: moment(this.props.schedule.estimate_start_at).toDate(),
      estimate_end_at: moment(this.props.schedule.estimate_end_at).toDate(),
      parent_id: this.props.schedule.parent_id ? this.props.schedule.parent_id : 0,
      schedule_id: this.props.schedule.id,
      loading: false,
    });
  }

  save = () => {
    if (this.state.loading) { return; }
    const completionPercentage = parseInt(this.state.completion, 10);

    if (this.state.start_at.getTime() > this.state.end_at.getTime() || this.state.estimate_start_at.getTime() > this.state.estimate_end_at.getTime()) {
      this.setState({ error: 34 });
      return;
    }

    if (!Number.isNaN(completionPercentage)) {
      if (completionPercentage < 0 || completionPercentage > 100 ) {
        this.setState({ error: 37 });
        return;
      }
    }

    if (Number.isNaN(completionPercentage)) {
      if ((completionPercentage !== '' && !Number.isInteger(completionPercentage)) || (completionPercentage !== null && !Number.isInteger(completionPercentage))) {
        this.setState({ error: 38 });
        return;
      }
    }

    this.setState({ loading: true }, () => {
      const start = this.props.formatDateDB(this.state.start_at);
      const end = this.props.formatDateDB(this.state.end_at);
      const estimateStart = this.props.formatDateDB(this.state.estimate_start_at);
      const estimateEnd = this.props.formatDateDB(this.state.estimate_end_at);

      const payload = {
        completion: this.state.completion,
        description: this.state.description,
        start_at: start,
        end_at: end,
        estimate_start_at: estimateStart,
        estimate_end_at: estimateEnd,
      };

      if (!!this.state.parent_id && this.state.parent_id !== '0') {
        payload.parent_id = this.state.parent_id;
      } else {
        payload.parent_id = null;
      }

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectSchedulesEdit(this.props.project.id, this.props.schedule.id, payload).then((contact) => {
        if (contact.error) { this.setState({ error: contact.error }); }
        this.props.onHide();
        this.props.onRefresh();
      });
    });
  }

  onChangeDatePicker = (date, target) => { 
    this.setState({ [target]: date })
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  render() {
    return <Modal show={this.props.show} onHide={this.props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.schedules.edit-schedule')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        { !this.state.loading && <Form.Group controlId="edit-schedule">
          <Form.Label>{I18n.t('views.schedules.create-schedule-description')}</Form.Label>
          <Form.Control type="text" onChange={this.onChange} name="description" value={this.state.description} />

          <Form.Label>{I18n.t('views.schedules.create-schedule-start_at')}</Form.Label>
          <DatePicker
            name="start_at"
            selected={this.state.start_at}
            onChange={(date) => this.onChangeDatePicker(date, "start_at")}
            className="datepicker-input"
            dateFormat="dd.MM.yyyy"
          />

          <Form.Label>{I18n.t('views.schedules.create-schedule-end_at')}</Form.Label>
          <DatePicker
            name="end_at"
            selected={this.state.end_at}
            onChange={(date) => this.onChangeDatePicker(date, "end_at")}
            className="datepicker-input"
            dateFormat="dd.MM.yyyy"
          />

          <Form.Label>{I18n.t('views.schedules.create-schedule-estimate_start_at')}</Form.Label>
          <DatePicker
            name="estimate_start_at"
            selected={this.state.estimate_start_at}
            onChange={(date) => this.onChangeDatePicker(date, "estimate_start_at")}
            className="datepicker-input"
            dateFormat="dd.MM.yyyy"
          />

          <Form.Label>{I18n.t('views.schedules.create-schedule-estimate_end_at')}</Form.Label>
          <DatePicker
            name="estimate_end_at"
            selected={this.state.estimate_end_at}
            onChange={(date) => this.onChangeDatePicker(date, "estimate_end_at")}
            className="datepicker-input"
            dateFormat="dd.MM.yyyy"
          />

          <Form.Label>{I18n.t('views.schedules.create-schedule-parent_id')}</Form.Label>
          <Form.Control as="select" onChange={this.onChange} name="parent_id" value={this.state.parent_id}>
            <option key="0" value="0"> - </option>
            {this.props.schedules.map((schedule) => {
              if (schedule.id === this.state.schedule_id) {
                return false;
              }

              return <option key={schedule.id} value={schedule.id}> {schedule.description} </option>;
            })}
          </Form.Control>
          <Form.Label>{I18n.t('views.schedules.create-schedule-completion')}</Form.Label>
          <Form.Control 
            type="text" 
            onChange={this.onChange} 
            name="completion" 
            value={this.state.completion}
          />
        </Form.Group>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.schedules.button-cancel')}</Button>
        <Button variant="primary" onClick={this.save}>{I18n.t('views.schedules.button-save')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default EditScheduleModal;
